import * as namespace from './namespace'

const vendors = ['ms', 'moz', 'webkit', 'o']
let raf = window.requestAnimationFrame
let crqaf = window.cancelAnimationFrame
const helper = namespace.register('sundio.helpers.requestAnimationFrame')
if (!raf) { vendors.some((vendor) => (raf = window[vendor + 'RequestAnimationFrame'])) }
if (!crqaf) {
  vendors.some((vendor) => (crqaf = (window[vendor + 'CancelAnimationFrame'] || window[vendor + 'CancelRequestAnimationFrame'])))
}

/**
 * RequestAnimationFrame
 * To perform animation and request browser to call a specific function to update the animation before the repaint
 */
export const requestAnimationFrame = raf
helper.requestAnimationFrame = requestAnimationFrame

/**
 * CancelAnimationFrame
 * To perform animation and request browser to call a specific function to update the animation before the repaint
 */
export const cancelAnimationFrame = crqaf
helper.cancelAnimationFrame = cancelAnimationFrame
