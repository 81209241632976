import { getStyle } from './css'
import * as namespace from './namespace'
import uid from '../core/uid'
import { requestAnimationFrame, cancelAnimationFrame } from './requestAnimationFrame'

const helper = namespace.register('sundio.helpers.scroll')
const elementIdAttribute = 'data-smooth-id'
const defaults = {
  mouse: {
    preventDefault: true,
    stopPropagation: true
  },
  touch: {
    preventDefault: true,
    stopPropagation: true
  },
  keyboard: {
    preventDefault: true,
    stopPropagation: true,
    keys: {
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      32: 'spaceBar',
      33: 'pageUp',
      34: 'pageDown',
      35: 'end',
      36: 'home'
    }
  }
}
const timeoutIds = {}
let blockMouseFn
let blockTouchFn
let blockKeyboardFn
const smoothProps = {
  filter: 0.2,
  fps: 60
}

/**
 * disable
 *
 * Disable the scrolling blocking the DOM events related.
 * By default it will apply on document, but if an optional element
 * had been received, will only apply on that element.
 *
 * @param {HTMLElement} [el]
 * @param {Object} [config]
 */
export function disable (el, config) {
  config = config || defaults

  if ('mouse' in config) {
    blockMouseFn = function (ev) { blockEvent(ev, config.mouse) };
    (el || window)
      .addEventListener(
        ('onwheel' in document.body) ? 'wheel' : 'mousewheel',
        blockMouseFn)
  }

  if ('touch' in config) {
    blockTouchFn = function (ev) { blockEvent(ev, config.touch) };
    (el || window)
      .addEventListener(
        'touchmove',
        blockTouchFn)
  }

  if ('keyboard' in config) {
    blockKeyboardFn = function (ev) { blockKeyEvent(ev, config.keyboard) };
    (el || document)
      .addEventListener(
        'keydown',
        blockKeyboardFn)
  }
}

helper.disable = disable

/**
 * enable
 *
 * Enable the scrolling unblocking the DOM events related if they was bound.
 * By default it will apply on document, but if an optional element
 * had been received, will only apply on that element.
 *
 * @param {HTMLElement} [el]
 */
export function enable (el) {
  if (blockMouseFn) {
    (el || window)
      .removeEventListener(
        ('onwheel' in document.body) ? 'wheel' : 'mousewheel',
        blockMouseFn)
    blockMouseFn = null
  }

  if (blockTouchFn) {
    (el || window)
      .removeEventListener(
        'touchmove',
        blockTouchFn)
    blockTouchFn = null
  }

  if (blockKeyboardFn) {
    (el || window)
      .removeEventListener(
        'keydown',
        blockKeyboardFn)
    blockKeyboardFn = null
  }
}

helper.enable = enable

/**
 * smooth
 *
 * Scroll smoothly to a coordinate target
 *
 * @param {HTMLElement} el
 * @param {Number} x - coordinate
 * @param y {number} y coordinate
 */
export function smooth (el, x, y) {
  // Use native smooth scroll if available and enabled (break if happens)
  const hasSmoothScrollEnabled = getStyle(el, 'scroll-behavior') === 'smooth'
  // 'scroll-behavior' === 'smooth' check returns true also for IE11 which is not correct.
  // Added "!isIE11Browser" for correcting the condition.
  const isIE11Browser = (window.navigator.userAgent.indexOf('Trident/') !== -1)

  if (hasSmoothScrollEnabled && el.scrollTo && !isIE11Browser) {
    el.scrollTo(x, y)
    return
  } if (hasSmoothScrollEnabled && !isIE11Browser) {
    el.scrollLeft = x
    el.scrollTop = y
    return
  }

  if (!el.getAttribute(elementIdAttribute)) { el.setAttribute(elementIdAttribute, uid()) }
  const elementId = el.getAttribute(elementIdAttribute)

  cancelAnimationFrame(timeoutIds[elementId])
  smoothScroll(el, x, y, null, null, elementId)
}

helper.smooth = smooth

function blockEvent (ev, evConfig) {
  ev = ev || window.event

  if (evConfig.preventDefault) {
    if (ev.preventDefault) ev.preventDefault()
    ev.returnValue = false
  }

  if (evConfig.stopPropagation && ev.stopPropagation) { ev.stopPropagation() }
}

function blockKeyEvent (ev, evConfig) {
  if (ev.keyCode && evConfig.keys) {
    if (evConfig.keys[ev.keyCode]) {
      blockEvent(ev, evConfig)
      return false
    }
  }
}

const setXY = (el, x, y) => {
  if (el.scrollTo) { el.scrollTo(x, y) } else {
    [el.scrollLeft, el.scrollTop] = [x, y]
  }
}

function smoothScroll (el, x, y, xLast, yLast, elementId) {
  const [xCurrent, yCurrent] = [parseFloat(el.scrollLeft), parseFloat(el.scrollTop)]
  const [xDifferent, yDifferent] = [(parseFloat(xCurrent) === parseFloat(xLast)),
    (parseFloat(yCurrent) === parseFloat(yLast))]

  if (xDifferent && yDifferent) { setXY(el, x, y) } else {
    // Scroll to next position
    const xNext = xDifferent ? x : Math.round((xCurrent * (1.0 - smoothProps.filter)) + (x * smoothProps.filter))
    const yNext = yDifferent ? y : Math.round((yCurrent * (1.0 - smoothProps.filter)) + (y * smoothProps.filter))
    setXY(el, xNext, yNext)

    // Schedule next frame
    timeoutIds[elementId] = requestAnimationFrame(() => smoothScroll(el, x, y, xCurrent, yCurrent, elementId))
  }
}
