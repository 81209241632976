import uid from './uid'
const EventEmitter = require('eventemitter3')

/**
 * ModelOptions type definition
 * @global
 * @typedef {Object}       ModelOptions
 * @typedef {Object}      [ModelOptions.defaults]    - The default attributes as fallback
 * @typedef {Collection}  [ModelOptions.collection]  - The parent collection which this model belongs to
 */

/**
 * ModelActionOptions type definition
 * @global
 * @typedef {Object}       ModelActionOptions
 * @typedef {Boolean}     [ModelOptions.silent]      - If true, does not fire events
 */

/**
 * A simple item model
 */
export default class Model {
  /**
   * Creates a new model
   *
   * @constructor
   * @param {Object} [attributes] - The Model attributes
   * @param {ModelOptions} [options] - Model options
   */
  constructor (attributes = {}, options = {}) {
    this.cid = uid()
    this.attributes = { ...(options.defaults || {}), ...attributes, ...{ cid: this.cid } }
    this.events = new EventEmitter()
    this.collection = options.collection || undefined
  }

  /**
   * get the specific value of the desired attribute
   *
   * @param {string} name - The attribute name
   *
   * @returns {Object} - The desired value
   */
  getAttribute (name) {
    return this.attributes[name]
  }

  /**
   * Assign a value to an attribute and emits the event
   *
   * @param {string} name - The attribute name
   * @param {Object} value - The attribute value
   * @param {ModelActionOptions} [options] - ModelActionOptions object
   *
   * @returns {Model} - The self instance
   */
  setAttribute (name, value, options = {}) {
    this.attributes[name] = value
    if (!options.silent) { this.events.emit('change', { [name]: value }) }
    return this
  }

  /**
   * Assign some attributes at the same time and emits the single event if proceed
   *
   * @param {Object} newAttributes - The new attributes object value, with a key and value for each one to change
   * @param {ModelActionOptions} [options] - ModelActionOptions object
   *
   * @returns {Model} - The self instance
   */
  setAttributes (newAttributes, options = {}) {
    Object.keys(newAttributes)
      .forEach(attributeKey => this.setAttribute(attributeKey, newAttributes[attributeKey], { silent: true }))
    if (!options.silent) { this.events.emit('change', newAttributes) }
    return this
  }

  /**
   * Pick some model attributes
   *
   * @param {...String} attributes - The attributes to pick
   *
   * @returns {Object} - The picked attributes and values
   */
  pick (...attributes) {
    return Object.keys(this.attributes)
      .filter((attrName) => attributes.indexOf(attrName) >= 0)
      .reduce((newObj, attr) => Object.assign(newObj, { [attr]: this.attributes[attr] }), {})
  }
}
