import * as namespace from './document/namespace'

const helper = namespace.register('sundio.helpers.utils')

/**
 * debounce
 * Creates and returns a new debounced version of the passed function which will postpone
 * its execution until after wait milliseconds have elapsed since the last time it was invoked
 *
 * At the end of the wait interval, the function will be called with the arguments that were
 * passed most recently to the debounced function.
 *
 * Pass true for the immediate argument to cause debounce to trigger the function on the leading
 * instead of the trailing edge of the wait interval
 *
 * @param func {Function}
 * @param wait {Number}
 * @param immediate {Boolean}
 * @returns {Function} debounced
 */
export function debounce (func, wait, immediate = false) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

helper.debounce = debounce

/**
 * range
 * Creates an array within a specified range
 *
 * @param start {Number} - start of range
 * @param end {Number} - end of range - inclusive - (if not specified, range will go from 1 to {start})
 * @returns {Function} debounced
 */

export function range (start, end) {
  if (end == null) {
    end = start
    start = 1
  }
  return Array.from({ length: (end - start + 1) }, (v, k) => k + start)
}

helper.range = range
