import * as namespace from './namespace'

const helper = namespace.register('sundio.helpers.css')

/**
 * cssTimeToMs
 * Convert CSS time string to MS
 *
 * @param {String} time - CSS time unit
 * @returns {Number} milliseconds
 */
export function cssTimeToMs (time) {
  const num = parseFloat(time)
  let unit = time.match(/m?s/)
  let milliseconds = 0
  if (unit) unit = unit[0]
  milliseconds = (unit === 'ms') ? num : (unit === 's') ? num * 1000 : milliseconds
  return milliseconds
}

helper.cssTimeToMs = cssTimeToMs

/**
 * hasTransition
 * Check if the element has a transition enabled or not
 *
 * @param {HTMLElement} el
 * @returns {Boolean} - true if there's a transition
 */
export function hasTransition (el) {
  return (helper.getStyle(el, 'transition-duration') !== '0s')
}

helper.hasTransition = hasTransition

/**
 * getStyle
 * Returns the style for specific element and property
 *
 * @param {HTMLElement} el
 * @param {String} prop - CSS property to check
 * @returns {String} propValue - CSS computed value for given property
 */
export function getStyle (el, prop) {
  return el.currentStyle
    ? el.currentStyle[prop]
    : document.defaultView.getComputedStyle(el, null).getPropertyValue(prop)
}

helper.getStyle = getStyle

/**
 * getComputedStyle
 * Returns the computed style for specific element and property
 *
 * @param {HTMLElement} el
 * @param {String} prop - CSS property to check
 * @returns {String} propValue - CSS computed value for given property
 */
export function getComputedStyle (el, prop) {
  const getComputedStyle = document.defaultView.getComputedStyle
  return getComputedStyle ? getComputedStyle(el, null).getPropertyValue(prop) : el.currentStyle[prop]
}

helper.getComputedStyle = getComputedStyle

/**
 * getStyles
 * Returns the style for specific element and multiple properties
 *
 * @param {HTMLElement} el
 * @param {String[]} props - CSS property to check
 * @returns {String} propValue - CSS computed value for given property
 */
export function getStyles (el, ...props) {
  return el.currentStyle
    ? props.map(prop => el.currentStyle[prop])
    : (() => {
        const computedStyle = document.defaultView.getComputedStyle(el, null)
        return props.map(prop => computedStyle.getPropertyValue(prop))
      })()
}

helper.getStyles = getStyles

/**
 * forceRepaint
 * Force a repaint for an element
 *
 * @param {HTMLElement} el
 */
export function forceRepaint (el) {
  helper.getStyle(el, 'width')
}

helper.forceRepaint = forceRepaint

/**
 * setHeightOverflow
 * Sets overflow height to bottom of page with a min and max height
 *
 * @param {HTMLElement} el
 * @param {Number} heightOffset - separation from bottom
 * @returns {void}
 */
export function setHeightOverflow (el, heightOffset) {
  const elementTop = el.getBoundingClientRect().top
  const viewportHeight = (window.innerHeight || document.documentElement.clientHeight)
  const maxHeight = parseInt(viewportHeight - elementTop - heightOffset)
  el.style.maxHeight = ''

  // If unit is em, we convert it to px
  const [curMinHeight, curMaxHeight] = getStyles(el, 'font-size', 'min-height', 'max-height')
    .map((propValue) => propValue.replace(/(\d+)([a-z]*)/g, '$1|$2')).map((propValue, a, array) => {
      const [value, unit] = propValue.split('|')
      return value * (unit === 'em' ? array[0].split('|')[0] : 1)
    }).slice(1)

  el.style.maxHeight = ((maxHeight > curMaxHeight)
    ? curMaxHeight
    : ((maxHeight < curMinHeight) ? curMinHeight : maxHeight)) + 'px'
}
helper.heightOverflow = setHeightOverflow

/**
 * getInnerHeight
 * Returns the innerHeight for specific element (excluding padding)
 *
 * @param {HTMLElement} el
 * @returns {Integer} innerHeight in px
 */
export function getInnerHeight (el) {
  let innerHeight = el.clientHeight
  getStyles(el, 'padding-top', 'padding-bottom').forEach(p => { innerHeight -= parseInt(p) })
  return innerHeight
}

helper.getInnerHeight = getInnerHeight
