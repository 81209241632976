export function destinationIcons (siteType, filterType) {
  const filterTypeLow = filterType ? filterType.toLowerCase() : null
  const siteTypeLow = siteType ? siteType.toLowerCase() : null

  switch (siteTypeLow) {
    case 'summer':
      return destinationIconsSummer[filterTypeLow]
    case 'winter':
      return destinationIconsWinter[filterTypeLow]
    default:
      return defaultDestinationIcons[filterTypeLow]
  }
}

const defaultDestinationIcons = {
  country: 'earth',
  region: 'pin-group',
  city: 'pin',
  accommodation: 'home',
  information: 'information-circle',
  accommodations: 'home',
  destinations: 'pin',
  flightticket: 'airplane'
}

const destinationIconsSummer = {
  country: 'earth',
  region: 'umbrella',
  city: 'pin',
  accommodation: 'home',
  information: 'information-circle',
  accommodations: 'home',
  destinations: 'pin',
  flightticket: 'airplane'
}

const destinationIconsWinter = {
  country: 'earth',
  region: 'snowflake',
  city: 'pin',
  accommodation: 'home',
  information: 'information-circle',
  accommodations: 'home',
  destinations: 'pin',
  flightticket: 'airplane'
}
