export function queueFilterEvent (ev, element, func, shouldEmit) {
  ev.preventDefault()
  ev.stopPropagation()
  const closestResult = (el, lastParentToCheck) => {
    do {
      if (el === lastParentToCheck) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el !== lastParentToCheck.parentElement && el.nodeType === 1)
    return null
  }
  const delegateTarget = closestResult(ev.target, element)
  if (!delegateTarget) {
    const filterElement = checkIfParentIsFilter(ev.target, element)
    if (!filterElement) {
      func()
      return
    }
    const widgetApi = filterElement[filterElement.dataset.jsComponent]
    if (widgetApi) {
      if (shouldEmit) {
        const parentComponent = document.querySelector('.w-search') || document.querySelector('.w-quick-search') || document.querySelector('.w-booking-gate')
        if (parentComponent) {
          const api = parentComponent['w-quick-search'] || parentComponent['w-search'] || parentComponent['w-booking-gate']
          api.events.once('fetchAndUpdateFinished', () => {
            if (filterElement.dataset.jsComponent === 'w-destination-filter') return
            if (filterElement.dataset.jsComponent === 'w-destination-multiple-filter') {
              document.querySelector('.w-filter--destination-multiple')[filterElement.dataset.jsComponent].open()
            } else {
              widgetApi.open()
            }
          })
        }
      } else {
        widgetApi.open()
      }
    }
    func()
  }
}

export function checkIfParentIsFilter (el, element) {
  do {
    if (el === element.parentElement) return null
    if (el.parentElement === null) return null
    if (el.classList.contains('w-filter')) return el
    el = el.parentElement || el.parentNode
  } while (el !== null && el !== element.parentElement)
  return null
}
