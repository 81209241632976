import uid from '../core/uid'

require('intersection-observer')
const EventEmitter = require('eventemitter3')

// Default options
// ===============

export const observerAPI = 'js-intersector'
const defaults = {
  root: null,
  rootMargin: '100px',
  numSteps: 1
}
const intersectors = {}

/**
 * Intersection observer
 */
class IntersectionObserver {
  /**
   * Creates the Intersector Observer with the defined settings
   *
   * @constructor
   * @param {Object} settings - Parameter to set own settings instead of using data-attributes
   */
  constructor (settings = {}) {
    this.settings = { ...defaults, ...settings }
    this.settings.threshold = this.settings.threshold || IntersectionObserver._buildThresholdList(this.settings.numSteps)

    this.observer = new window.IntersectionObserver(IntersectionObserver._handleIntersect, this.settings)
  }

  // Private functions
  // --------------------------------
  static _buildThresholdList (numSteps) {
    const thresholds = []

    for (let i = 1.0; i <= numSteps; i++) { thresholds.push(i / numSteps) }
    thresholds.push(0)

    return thresholds
  }

  static _handleIntersect (entries) {
    entries.forEach((entry) => {
      const elementObserverApi = entry.target[observerAPI]
      if (elementObserverApi) {
        const ratio = entry.intersectionRatio
        if (ratio > elementObserverApi.prevRatio) {
          elementObserverApi.events.emit('enter', ratio)
        } else {
          elementObserverApi.events.emit('leave', ratio)
        }

        if (ratio === 1) {
          elementObserverApi.events.emit('visible')
        } else if (ratio === 0) {
          elementObserverApi.events.emit('hidden')
        }

        elementObserverApi.prevRatio = ratio
      }
    })
  }

  // Public functions
  // --------------------------------
  /**
   * Observe intersection for desired element.
   *
   * @param {HTMLElement} element - The target element.
   * @returns {void}
   */
  observe (element) {
    if (!element[observerAPI]) {
      element[observerAPI] = {
        events: new EventEmitter(),
        prevRatio: 0
      }
      this.observer.observe(element)
    }
  }

  /**
   * Remove the intersector observer from target element.
   *
   * @param {HTMLElement} element - The target element.
   * @returns {void}
   */
  unobserve (element) {
    try {
      this.observer.unobserve(element)
      element[observerAPI].events.removeAllListeners()
      delete element[observerAPI]
    } catch { }
  }
}

export const documentObserver = function (settings = {}) {
  const { r, ...settings2 } = settings
  if (settings.root) { settings2.rootId = (settings.root.dataset.id || (settings.root.dataset.id = uid())) }
  intersectors[JSON.stringify(settings2)] = intersectors[JSON.stringify(settings2)] || new IntersectionObserver(settings)
  return intersectors[JSON.stringify(settings2)]
}
