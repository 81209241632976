import * as namespace from './namespace'
const helper = namespace.register('sundio.helpers.DomEventsHelper')

class DomEventsHelperClass {
  /**
   * DOM Events manager constructor.
   *
   * @constructor
   * functionMap - saves all the functions for every {{namespace}}.{{event}}
   */
  constructor () {
    this.functionMap = {}
  }

  // Public functions
  // --------------------------------
  // Detach and apply event array to a DOM element
  attachEvents (events, namespace = null) {
    this.detachEvents(events, namespace)
    this._applyEvents(events, namespace)
  }

  // Detach events from a DOM element
  detachEvents (events = [], namespace = null) {
    events.forEach((event) => {
      this._processEvent(event, false, namespace)
    })
  }

  // Private functions
  // --------------------------------
  // Apply event array to aDOM element
  _applyEvents (events = [], namespace = null) {
    events.forEach((event) => {
      this._processEvent(event, true, namespace)
    })
  }

  // Add/removes one or more event listeners for aDOM element
  _processEvent (event, bind, namespace) {
    const el = event[0]
    if (!el) {
      return
    }

    Object.entries(event[1]).forEach(([ev, fn]) => {
      if (namespace) { ev = [ev, namespace].join('.') }
      if (fn) {
        this.functionMap[ev] = fn
        el[bind ? 'addEventListener' : 'removeEventListener'](ev.split('.')[0], fn, event[2] || false)
      }
    })
  }
}

const domEventsHelper = new DomEventsHelperClass()
export default domEventsHelper
helper.domEventsHelper = domEventsHelper
