import * as namespace from '../../document/namespace'

const core = namespace.register('sundio.core')

export const registeredWidgets = core.registeredWidgets || []
core.registeredWidgets = registeredWidgets

/**
 * Registers a new widget in the directory
 *
 * @param {Function} constructor - The widget constructor to instantiate any
 * @param {String} name - The widget API name
 * @param {Object} [options] - The widget options
 *
 */

export function registerWidget (constructor, name, options = {}) {
  if (!constructor || !name || !options) return
  if (registeredWidgets.find(widget => widget.name === name)) return
  registeredWidgets.push({
    name,
    constructor,
    options: { name: '', ...options },
    instances: []
  })
}
