import config from './component-config'
import { registeredComponents } from './component-directory'
import { toCamelCase } from '../../helpers/string'
const EventEmitter = require('eventemitter3')

/**
 * Component
 *
 */
export default class Component {
  /**
   * Creates a new component, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   * @param {String} name - The component name
   *
   */

  constructor (element, name) {
    if (!this._checkConstructorArguments(element, name)) return
    this.element = element
    this.name = name
    this.config = registeredComponents.find(component => component.name === this.name)
    this.props = {}
    this.propsType = {}
    this.events = new EventEmitter()
    this.config.props.forEach((prop) => {
      this.props[prop.name] = this._getPropFromElement(prop)
      this.propsType[prop.name] = prop.config.type
    })
    this.observer = this._initObserver()
    /* this.observer = new window.MutationObserver((records) => {
      this._updateStatesOnAttrChange(records)
    })
    this.observer.observe(this.element, {attributes: true, childList: false, characterData: false}) */

    element[this.name] = {
      element: this.element,
      props: this.props,
      events: this.events,
      _getPropInstance: this._getPropInstance.bind(this),
      setProp: this.setProp.bind(this),
      setProps: this.setProps.bind(this),
      getProp: this.getProp.bind(this)
    }
  }

  /**
   * Set a known prop with new given value
   * @param {String} propName - The property name
   * @param {ComponentPropertyValue} value - The state value
   * @param {Object} options - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   * @param {Boolean} options.forceUpdate - If true, forces to update
   * @param {Boolean} options.stopPropagation - If true, stops the propagation or reflection
   * @returns {Component} self instance
   */
  async setProp (propName, value, options = {}) {
    // Check if the propName is a known propName or not, if not, reject with an error
    if (!Object.prototype.hasOwnProperty.call(this.props, propName)) { throw new Error(`${propName} is not a known prop in ${this.name} component`) }

    // Check if given value is different than the current one, if it's the same, resolve with no errors

    // Check if the property is an HTMLElement, as using JSON.stringify on it can cause a circular structure error
    if (this.props[propName] instanceof window.HTMLElement && value instanceof window.HTMLElement) {
      if (this.props[propName].isEqualNode(value) && !options.forceUpdate) return this
    } else if (this.propsType[propName] === 'HTMLElement') {
      if (!this.props[propName] && !value && !options.forceUpdate) return this
    } else {
      if (JSON.stringify(this.props[propName]) === JSON.stringify(value) && !options.forceUpdate) return this
    }

    // Check if given value type is the expected one, if not throw an error
    // An overridden method for checking will be used if it is present
    const property = this._getPropInstance(propName)
    const checkPropMethodName = toCamelCase(`check ${propName}`)
    const checkPropMethodFn = this[checkPropMethodName] && (typeof this[checkPropMethodName] === 'function')
      ? this[checkPropMethodName].bind(this)
      : property.isAllowedValue.bind(property)
    if (!checkPropMethodFn(value)) { throw new Error(`${value} is not an allowed value for ${propName} prop in ${this.name} component`) }

    const oldValue = this.props[propName]
    // Set the new value to prop
    this.props[propName] = value

    // Check if there's an overridden method for the given prop, and use it if is present
    const setPropMethodName = toCamelCase(`set ${propName}`)
    if (this[setPropMethodName] && (typeof this[setPropMethodName] === 'function')) {
      await this[setPropMethodName](value, options, oldValue)
    } else {
      property.setValueToElementAttributes(this.element, value)
    }

    if (!options.silent) this.events.emit('propChanged', { name: propName, value, oldValue })
    return this
  }

  /**
   * Set some known props with new given values
   * @param {Object} props - The props to set and it's values. Expressed as key[prop]:value[newValue]
   * @param {Object} options - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   * @param {Boolean} options.forceUpdate - If true, forces to update
   * @returns {Promise}
   */
  async setProps (props = {}, options = {}) {
    const propsSetters = Object.keys(props)
      .map(propName => this.setProp(propName, props[propName], options))
    return Promise.all(propsSetters)
  }

  getProp (propName) {
    return this.props[propName]
  }

  _getPropFromElement (prop) {
    // Possible usage of an overridden method for the given prop, or use the default one
    const getPropMethodName = toCamelCase(`get ${prop.name}`)
    return (this[getPropMethodName] && (typeof this[getPropMethodName] === 'function'))
      ? this[getPropMethodName]()
      : prop.getValueFromElementAttributes(this.element)
  }

  _getPropInstance (propName) {
    return this.config.props.find(prop => { return prop.name === propName })
  }

  /**
   * Init components calling it's constructor over the HTML Elements with a data-api
   * matching the registered component names.
   */
  static initDocumentComponentsFromAPI (htmlFragment = window.document) {
    // Find for candidates
    const componentCandidates = [...htmlFragment.querySelectorAll(`[${'data-js-component'}]`),
      ...((htmlFragment !== window.document) && htmlFragment.getAttribute('data-js-component') ? [htmlFragment] : [])]
    // Group candidates per component type
    const sortedCandidates = {}
    componentCandidates.forEach(element => {
      const dataApiContent = element.getAttribute(config.dataApi).split(' ')
      dataApiContent.forEach(componentApi => {
        if (element[componentApi]) return
        sortedCandidates[componentApi] = sortedCandidates[componentApi] || []
        sortedCandidates[componentApi].push(element)
      })
    })

    // Initialize components by it's registered order
    registeredComponents.forEach(registeredComponent => {
      const elementCandidates = sortedCandidates[registeredComponent.name] || []
      elementCandidates.forEach(el => {
        // eslint-disable-next-line no-unused-vars
        const componentInstance = new registeredComponent.constructor(el)
        // Bind action elements
        if (registeredComponent.options.actionElements && el.id) {
          window.document.querySelectorAll(`[data-${registeredComponent.name}__id="${el.id}"]`).forEach(actionElement => {
            const actionName = actionElement.getAttribute(`data-${registeredComponent.name}__action`)
            if (el[registeredComponent.name][actionName] && typeof el[registeredComponent.name][actionName] === 'function') {
              actionElement.addEventListener('click', (ev) => {
                el[registeredComponent.name][actionName](actionElement)
              })
            }
          })
        }
      })
    })
  }

  /**
   * Init components actionElements
   */
  static initComponentActionElements (htmlFragment = window.document) {
    registeredComponents.forEach(registeredComponent => {
      htmlFragment.querySelectorAll(`[data-${registeredComponent.name}__id]`).forEach(actionElement => {
        const actionName = actionElement.getAttribute(`data-${registeredComponent.name}__action`)
        const componentTarget = window.document.getElementById(actionElement.getAttribute(`data-${registeredComponent.name}__id`))
        if (componentTarget && componentTarget[registeredComponent.name][actionName] && typeof componentTarget[registeredComponent.name][actionName] === 'function') {
          actionElement.addEventListener('click', (ev) => {
            componentTarget[registeredComponent.name][actionName](actionElement)
          })
        }
      })
    })
  }

  /**
   * Destroy components calling it's destroy method if available.
   */
  static destroyDocumentComponents (htmlFragment = window.document) {
    // Find for candidates on fragment
    const componentCandidates = [...htmlFragment.querySelectorAll(`[${'data-js-component'}]`),
      ...((htmlFragment !== window.document) && htmlFragment.getAttribute('data-js-component') ? [htmlFragment] : [])]
    // Access to API and invoke destroy method if any
    componentCandidates.forEach(element => {
      const dataApiContent = element.getAttribute(config.dataApi).split(' ')
      dataApiContent.forEach(componentApi => {
        if (element[componentApi] && element[componentApi].destroy) {
          element[componentApi].destroy()
        }
      })
    })
  }

  _checkConstructorArguments (element, name) {
    let valid = true
    if (!element || !(element instanceof window.HTMLElement)) {
      valid = false
      throw new Error('A component instance needs an HTMLElement as first argument')
    }
    if (!name || typeof name !== 'string') {
      valid = false
      throw new Error('A component instance needs a ComponentName as String as second argument')
    }
    if (!registeredComponents.find(component => component.name === name)) {
      valid = false
      throw new Error(`${name} is not a registered component`)
    }
    return valid
  }

  _initObserver () {
    if (!this.config.observer) return undefined
    this.observer = new window.MutationObserver((records) => {
      this._observedChangesOnElement(records)
    })
    this.observer.observe(this.element, { attributes: this.config.observeAttributes, childList: this.config.observeChildList, characterData: false })
  }

  _observedChangesOnElement (records) {
    const newProps = this._getPropsFromElement()
    if (Object.is(this.props, newProps)) return true
    // Element changes affected some properties
    // TODO: Set every change
  }
}
