import { register } from '../document/namespace'

import { sv as Dates } from './dates/main'
import { sv as Flatpickr } from './flatpickr/main'
import { sv as Global } from './global/main'

import { sv as Autocomplete } from '../../modules/components/autocomplete/locales'
import { sv as ParticipantsSelector } from '../../../shared/modules/components/participants-selector/locales'
import { sv as DateSelector } from '../../../shared/modules/components/date-selector/locales'
import { da as ProductCard } from '../../../shared/modules/components/product-card/locales'
import { sv as Search } from '../../../shared/modules/widgets/search/locales'
import { sv as ReviewsList } from '../../modules/widgets/reviews-list/locales'
import { sv as ReviewsTeaser } from '../../modules/widgets/reviews-teaser/locales'
import { sv as CarRental } from '../../../shared/modules/widgets/car-rental/locales'
import { sv as PromotedPrice } from '../../../shared/modules/widgets/promoted-price/locales'
import { da as BookingGate } from '../../../shared/modules/widgets/booking-gate/locales'
import { sv as RoomDropdown } from '../../../shared/modules/widgets/room-dropdown/locales'
import { sv as RoomList } from '../../../shared/modules/widgets/room-list/locales'
import { sv as RoomSelector } from '../../../shared/modules/widgets/room-selector/locales'
import { sv as DdGrid } from '../../../shared/modules/widgets/dd-grid/locales'
import { sv as FlightSelector } from '../../../shared/modules/widgets/flight-bus-selector/flight-selector-locales'

const i18n = register('sundio.i18n.sv')

export const dates = Dates
i18n.dates = dates

export const flatpickr = Flatpickr
i18n.flatpickr = flatpickr

export const global = Global
i18n.global = global

// Components
// --------------------------------------------

// Autocomplete
export const autocomplete = Autocomplete
i18n.autocomplete = autocomplete

// Participants Selector
export const participantsSelector = ParticipantsSelector
i18n.participantsSelector = participantsSelector

// Date Selector
export const dateSelector = DateSelector
i18n.dateSelector = dateSelector

// product card
export const productCard = ProductCard
i18n.productCard = productCard

// Widgets
// --------------------------------------------

// Search
export const search = Search
i18n.search = search

// Reviews List
export const reviewsList = ReviewsList
i18n.reviewsList = reviewsList

// Reviews teaser
export const reviewsTeaser = ReviewsTeaser
i18n.reviewsTeaser = reviewsTeaser

// Car rental
export const carRental = CarRental
i18n.carRental = carRental

// Promoted price
export const promotedPrice = PromotedPrice
i18n.promotedPrice = promotedPrice

// Booking gate
export const bookingGate = BookingGate
i18n.bookingGate = bookingGate

// Room dropdown
export const roomDropdown = RoomDropdown
i18n.roomDropdown = roomDropdown

// Room list
export const roomList = RoomList
i18n.roomList = roomList

// Room selector
export const roomSelector = RoomSelector
i18n.roomSelector = roomSelector

// Dd grid
export const ddGrid = DdGrid
i18n.ddGrid = ddGrid

// Flight selector
export const flightSelector = FlightSelector
i18n.flightSelector = flightSelector
