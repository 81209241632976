import ComponentProperty from './component-property'
import * as namespace from '../../document/namespace'

const core = namespace.register('sundio.core')

export const registeredComponents = core.registeredComponents || []
core.registeredComponents = registeredComponents
/**
 * Registers a new component in the directory
 *
 * @param {Function} constructor - The component constructor to instantiate any
 * @param {String} name - The component API name
 * @param {Object} options - The component options
 * @param {ComponentProperties} options.props - The component properties configuration
 *
 */
export function registerComponent (constructor, name, options) {
  if (!constructor || !name || !options) return
  if (registeredComponents.find(component => component.name === name)) return
  registeredComponents.push({
    name,
    constructor,
    options: Object.assign({
      actionElements: false,
      name: '',
      observer: false,
      observeAttributes: false,
      observeChildList: false,
      props: []
    }, options),
    props: Array.isArray(options.props) ? options.props.map((prop) => new ComponentProperty(prop)) : []
  })
}
