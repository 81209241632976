
export const da = {
  previousMonth: 'Forrige Måned',
  nextMonth: 'Næste Måned',
  months: 'Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December'.split('_'),
  months_short: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
  weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
  weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
  weekNumbers: true,
  isUnreal: 'Datoen findes ikke',
  isOutOfBounds: 'Den valgte dato er ikke gyldig'
}

export const de = {
  previousMonth: 'voriger Monat',
  nextMonth: 'nächster Monat',
  months: 'Januar_Februar_März_April_Mei_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  months_short: 'Jan_Feb_Mär_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Dez'.split('_'),
  weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
  weekNumbers: true,
  isUnreal: 'Das Datum existiert nicht',
  isOutOfBounds: 'Diese Auswahl ist nicht gültig'
}

export const en = {
  previousMonth: 'Previous Month',
  nextMonth: 'Next Month',
  months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
  months_short: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
  weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
  weekdaysShort: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
  weekNumbers: false,
  isUnreal: 'The date does not exist',
  isOutOfBounds: 'This date is not valid for the selected option'
}

export const nb = {
  previousMonth: 'Forrige måned',
  nextMonth: 'Neste måned',
  months: 'Januar_Februar_Mars_April_Mai_Juni_Juli_August_September_Oktober_November_Desember'.split('_'),
  months_short: 'Jan_Feb_Mar_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
  weekdays: 'Søndag_Mandag_Tirsdag_Onsdag_Torsdat_Fredag_Lørdag'.split('_'),
  weekdaysShort: 'Sø_Ma_Ti_On_To_Fr_Lø'.split('_'),
  weekNumbers: false,
  isUnreal: 'The date does not exist',
  isOutOfBounds: 'This date is not valid for the selected option'
}

export const fr = {
  previousMonth: 'Le mois dernier',
  nextMonth: 'Le mois prochain',
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  months_short: 'janv_févr_mars_avril_mai_juin_juil_août_sept_oct_nov_déc'.split('_'),
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim_lun_mar_mer_jeu_ven_sam'.split('_'),
  weekNumbers: false,
  isUnreal: 'Cette date n\'est pas disponible',
  isOutOfBounds: 'Cette date n\'est pas valide'
}

export const nl = {
  previousMonth: 'Vorige Maand',
  nextMonth: 'Volgende Maand',
  months: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split('_'),
  months_short: 'Jan_Feb_Maa_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
  weekdays: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split('_'),
  weekdaysShort: 'zo_ma_di_wo_do_vr_za'.split('_'),
  weekNumbers: true,
  isUnreal: 'Deze datum bestaat niet',
  isOutOfBounds: 'Deze datum is niet geldig voor de geselecteerde optie'
}

export const sv = {
  previousMonth: 'Förra Månaden',
  nextMonth: 'Nästa Månad',
  months: 'Januari_Februari_Mars_April_Maj_Juni_Juli_Augusti_September_Oktober_November_December'.split('_'),
  months_short: 'Jan_Feb_Mar_Apr_Maj_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
  weekdays: 'Söndag_Måndag_Tisdag_Onsdag_Torsdag_Fredag_Lördag'.split('_'),
  weekdaysShort: 'sö_må_ti_on_to_fr_lö'.split('_'),
  weekNumbers: true,
  isUnreal: 'Det valda datumet är inte tillgängligt',
  isOutOfBounds: 'Vänligen välj ett annat datum'
}
