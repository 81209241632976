import * as urlHelper from '../document/url'

/**
 * A JSON asynchronous fetcher
 */
export default class JSONFetcher {
  /**
   * Creates a new JSONFetcher, and fetches the content if a src is available
   * @constructor
   * @param {Object} options - The given options
   * @param {String} options.src - The data source
   */
  constructor ({ src = '' } = {}) {
    this.options = { src }
  }

  /**
   * Fetches data from source and parameters
   * @async
   * @param {String} [src=options.src] - The data source path
   * @param {Object} [params={}] - The params in the url fetch
   * @param {Boolean} useBracketArrays - Use brackets on keys when value is an array, eg. myParam[0]=one&myParam[1]=two
   * @returns {Promise} The fetching process resolved with parsed JSON content
   */
  fetch (src = this.options.src, params = {}, useBracketArrays = true) {
    const url = new window.URL(urlHelper.getAbsoluteUrl(src))
    params = useBracketArrays ? JSONFetcher.processBracketArrays(params) : params
    JSONFetcher.keyValues(params).forEach(function ({ key, value }) {
      url.searchParams.append(key, value)
    })

    return new Promise((resolve, reject) => {
      this.data = window.fetch(url.href, {
        credentials: 'include',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(response => {
          resolve(response.json())
        })
        .catch(reject)
    })
  }

  /**
   * Returns the list of key and value of the given object
   * @async
   * @param {Object} obj - The given object
   * @returns Array of key and value of the given object
   */
  static keyValues (obj) {
    const keyValuesList = []
    Object.keys(obj).forEach(function (element) {
      keyValuesList.push({ key: element, value: obj[element] })
    })

    return keyValuesList
  }

  /**
   * Flatten arrays in objects onto keys with brackets for sending params as query strings like myParam[0]=one&myParam[1]=two
   * @param {Object} obj - The given object
   * @returns {Object} the transformed object
   */
  static processBracketArrays (obj) {
    const computedObject = {}
    Object.keys(obj).forEach(function (key) {
      const value = obj[key]
      if (Array.isArray(value)) {
        value.forEach((val, i) => {
          computedObject[`${key}[${i}]`] = val
        })
      } else {
        computedObject[key] = value
      }
    })
    return computedObject
  }
}
