/**
 * ---------------
 *     README
 * ---------------
 * ⚠ About this ⚠
 *
 * The reason to have this file here, is because the differences on data models in between
 * Sitecore and Design System components. The responsibility of this file is, given an API
 * model, obtain a ready to render model.
 *
 */

import { createAcmUrlFromParams } from '../../modules/components/price/c-price.template'
import { getProcessedCollectionDataFromItem } from '../helpers/collection-mapper'

const BREADCRUMBS_ARRAY = ['countryName', 'regionName', 'cityName']

const DEFAULT_OPTIONS = {
  variant: 'default',
  priceLegend: 'From',
  acmBaseUrl: '',
  acmModalId: '',
  imageSettingsForVariantDefault: {
    sync: false,
    resolve: 'intersect',
    ratio: '3:2'
  },
  imagePlaceholderSrcForVariantDefault: (url) => `${url}?width=24&height=16&scale=both&mode=crop`,
  imageResolvedSrcForVariantDefault: (url) => `${url}?width={W}&height={H}&scale=both&mode=crop`,
  imageSettingsForVariantListed: {
    sync: true,
    ratio: '1:1'
  },
  imageResolvedSrcForVariantListed: (url) => `${url}?width=150&height=150&scale=both&mode=crop`,
  userRating: {
    variant: 'bubbled'
  }
}

export function processPackageItem (item, options = {}) {
  const currentOptions = { ...DEFAULT_OPTIONS, ...options }
  return {
    starRating: item.rating ? item.rating.toString() : undefined,
    name: item.name,
    packages: {
      includedServices: item.price.includedServices
    },
    collection: getProcessedCollectionDataFromItem(item),
    ...getProcessedImageDataFromItem(item, currentOptions),
    ...getProcessedUserRatingDataFromItem(item, currentOptions),
    ...getProcessedBreadcrumbsDataFromItem(item),
    ...getProcessedPriceDataFromItem(item, currentOptions),
    ...getProcessedItemLinkDataFromItem(item, options.originList),
    isAccoTip: item.isAccoTip,
    flightRoutes: item.flightRoutes
  }
}

function getProcessedImageDataFromItem (item, options) {
  return item.image
    ? (() => {
        switch (options.variant) {
          case 'listed':
            return {
              image: {
                ...options.imageSettingsForVariantListed,
                title: item.name,
                resolvedSrc: options.imageResolvedSrcForVariantListed(item.image)
              }
            }
          default:
            return {
              image: {
                ...options.imageSettingsForVariantDefault,
                title: item.name,
                placeholderSrc: options.imagePlaceholderSrcForVariantDefault(item.image),
                resolvedSrc: options.imageResolvedSrcForVariantDefault(item.image)
              }
            }
        }
      })()
    : {}
}

function getProcessedUserRatingDataFromItem (item, options) {
  return parseFloat(item.userRating) === 0
    ? null
    : {
        userRating: {
          ...options.userRating,
          scoreGrade: Math.round(parseFloat(item.userRating) * 10) / 10
        }
      }
}

function getProcessedBreadcrumbsDataFromItem (item) {
  return {
    breadcrumbs: {
      items: BREADCRUMBS_ARRAY.reduce((items, key) => {
        if (item[key]) items.push({ text: item[key] })
        return items
      }, [])
    }
  }
}

function getProcessedPriceDataFromItem (item, options) {
  return item.price
    ? {
        price: {
          value: item.price.averagePrice.toString(),
          currencyPosition: item.price.currencySettings.symbolPosition === 'BeforeAmount'
            ? 'before'
            : 'after',
          currency: item.price.currencySettings.symbol,
          legend: options.priceLegend,
          label: options.priceLabel,
          labelGrayed: options.priceLabelGrayed,
          acmHideInfoIcon: options.acmHideInfoIcon,
          ...getProcessedAcmDataFromItemPrice(item.price, options)
        }
      }
    : {}
}

function getProcessedAcmDataFromItemPrice (itemPrice, options) {
  return itemPrice.acmInformation
    ? itemPrice.acmInformation.acmUrl
      ? {
          acmUrl: itemPrice.acmInformation.acmUrl,
          acmModalId: options.acmModalId
        }
      : {
          acmUrl: createAcmUrlFromParams(options.acmBaseUrl, itemPrice.acmInformation).href,
          acmModalId: options.acmModalId
        }
    : {}
}

function getProcessedItemLinkDataFromItem (item, originList) {
  const url = item.urlWithFilters || item.url
  return url
    ? {
        packageItemLink: {
          href: url.includes('?') ? url + '&originList=' + originList : url + '?originList=' + originList,
          title: item.name
        }
      }
    : {
        packageItemLink: null
      }
}
