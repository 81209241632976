
export const da = {
  ok: 'OK',
  accept: 'Godkend',
  cancel: 'Annuller',
  search: 'Søg',
  previous: 'Forrige',
  next: 'Næste',
  readMore: 'Læs mere',
  readLess: 'Læs mindre',
  showMore: 'Vis mere',
  showLess: 'Vis mindre',
  loading: 'Loader',
  subscribe: 'Tilmeld',
  separator: 'og',
  clearFilter: 'Klart filter',
  sorryNoMatches: 'Beklager, ingen matcher til'
}

export const de = {
  ok: 'OK',
  accept: 'Akzeptieren',
  cancel: 'Abbrechen',
  search: 'Suche',
  previous: 'Zurück',
  next: 'Weiter',
  readMore: 'Mehr lesen',
  readLess: 'Weniger lesen',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  loading: 'Lädt',
  subscribe: 'Abonnieren',
  separator: 'und',
  clearFilter: 'Filter löschen',
  sorryNoMatches: 'Entschuldigung, keine Übereinstimmungen für'
}

export const en = {
  ok: 'OK',
  accept: 'Accept',
  cancel: 'Cancel',
  search: 'Search',
  previous: 'Previous',
  next: 'Next',
  readMore: 'Read more',
  readLess: 'Read less',
  showMore: 'Show more',
  showLess: 'Show less',
  loading: 'Loading',
  subscribe: 'Subscribe',
  separator: 'and',
  clearFilter: 'Clear filter',
  sorryNoMatches: 'Sorry, no matches for'
}

export const nb = {
  ok: 'OK',
  accept: 'Aksepterer',
  cancel: 'Avbryt',
  search: 'Søk',
  previous: 'Tidligere',
  next: 'Neste',
  readMore: 'Les mer',
  readLess: 'Les mindre',
  showMore: 'Vis mer',
  showLess: 'Vis less',
  loading: 'Laster',
  subscribe: 'Abonnere',
  separator: 'og',
  clearFilter: 'Klart filter',
  sorryNoMatches: 'Beklager, ingen treff for'
}

export const fr = {
  ok: 'OK',
  accept: 'Valider',
  cancel: 'Annuler',
  search: 'Rechercher',
  previous: 'Précédent',
  next: 'Suivant',
  readMore: 'Lire plus',
  readLess: 'Lire moins',
  showMore: 'Voir plus',
  showLess: 'Voir moins',
  loading: 'Chargement en cours',
  subscribe: 'Souscrire',
  separator: 'et',
  clearFilter: 'Effacer le filtre',
  sorryNoMatches: 'Désolé, aucun résultat pour'
}

export const nl = {
  ok: 'Akkoord',
  accept: 'Accepteren',
  cancel: 'Annuleren',
  search: 'Zoeken',
  previous: 'Vorige',
  next: 'Volgende',
  readMore: 'Lees meer',
  readLess: 'Lees minder',
  showMore: 'Toon meer',
  showLess: 'Toon minder',
  loading: 'Laden',
  subscribe: 'Inschrijven',
  separator: 'en',
  clearFilter: 'Filter wissen',
  sorryNoMatches: 'Sorry, geen resultaten voor'
}

export const sv = {
  ok: 'Spara',
  accept: 'Acceptera',
  cancel: 'Stäng',
  search: 'Sök',
  previous: 'Föregående',
  next: 'Nästa',
  readMore: 'Läs mer',
  readLess: 'Göm',
  showMore: 'Visa mer',
  showLess: 'Göm',
  loading: 'Laddar',
  subscribe: 'Prenumerera',
  separator: 'och',
  clearFilter: 'Rensa filtret',
  sorryNoMatches: 'Tyvärr, inga träffar för'
}
