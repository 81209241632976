import uid from './uid'
import Model from './model'
const EventEmitter = require('eventemitter3')

/**
 * CollectionOptions type definition
 * @global
 * @typedef {Object}   CollectionOptions
 * @typedef {Model}   [CollectionOptions.model]      - The Model constructor of each collection item
 * @typedef {Object}  [CollectionOptions.defaults]   - The Model default attributes
 */

/**
 * A generic collection of models
 */
export default class Collection {
  /**
   * Creates a new collection
   * @constructor
   *
   * @param {(Object[]|Model[])} [items] - The items to populate collection
   * @param {CollectionOptions} [options]
   */
  constructor (items = [], options = {}) {
    this.cid = uid()
    this.models = []
    this.Model = options.model || Model
    this.modelOptions = {
      collection: this,
      defaults: options.defaults || {}
    }
    this.events = new EventEmitter()
    this.events.on('added', () => this.events.emit('updated'))
    this.events.on('removed', () => this.events.emit('updated'))
    this.events.on('reset', () => this.events.emit('updated'))

    if (items && items.length) this.addItems(items, { silent: true })
  }

  /**
   * Adds the given items to the collection
   *
   * @param {(Object[]|Model[])} items - The items to populate collection
   * @param {ModelActionOptions} [options]
   *
   * @returns {Collection} - Current collection
   */
  addItems (items, options = {}) {
    items.forEach(item => this.addItem(item, { silent: true }))
    if (!options.silent) { this.events.emit('added') }
    return this
  }

  /**
   * Adds the given item to the collection
   *
   * @param {(Object|Model)} item - The item to add
   * @param {ModelActionOptions} [options]
   *
   * @returns {Model} - The added item Model
   */
  addItem (item, options = {}) {
    const itemToAdd = item instanceof this.Model ? item : new this.Model(item, this.modelOptions)
    this.models.push(itemToAdd)
    const collection = this
    itemToAdd.events.on('change', () => { collection.events.emit('updated') })
    itemToAdd.events.on('reset', (model) => { collection.events.emit('itemReset', model) })
    if (!options.silent) { this.events.emit('added') }
    return itemToAdd
  }

  /**
   * Resets the collection
   * Deletes all the current models, and set the given ones
   *
   * @param {(Object[]|Model[])} items - The fresh items to reset with
   * @param {ModelActionOptions} [options]
   *
   * @returns {Collection} - The self instance
   */
  reset (items = [], options = {}) {
    this.models = []
    this.addItems(items, { silent: true })
    if (!options.silent) { this.events.emit('reset') }
    return this
  }

  /**
   * Find for an item matching the given criteria
   *
   * @param {String} attr - The attribute to search for
   * @param {*} value - The value for given attribute to match
   *
   * @returns {(Model|undefined)} - Matched item Model, or undefined if no
   * item was matching the given arguments
   */
  findWhere (attr, value) {
    return this.models.find(model => { return model.attributes[attr] === value })
  }

  /**
   * Filter for items matching the given criteria
   *
   * @param {String} attr - The attribute to search for
   * @param {*} value - The value for given attribute to match
   *
   * @returns {Model[]} - Matched items models
   */
  where (attr, value) {
    return this.models.filter(model => { return model.attributes[attr] === value })
  }

  /**
   * Pick some models attributes
   *
   * @param {...String} attributes - The attributes to pick
   *
   * @returns {Object[]} - The picked attributes and values
   */
  pick (...attributes) {
    return this.models.map(model => model.pick(...attributes))
  }

  /**
   * Remove a model from the collection
   *
   * @param {Model} model - The model to remove
   * @param {ModelActionOptions} [options]
   *
   * @returns {Collection} - The self instance
   */
  remove (model, options = {}) {
    if (this.models.includes(model)) {
      this.models.splice(this.models.indexOf(model), 1)
      if (!options.silent) { this.events.emit('removed') }
    }
    return this
  }

  /**
   * Returns given models sorted alphabetically for the given attribute
   *
   * @param {String} attribute - The attribute to sort by
   * @param {Collection||Model[]} collectionModels - The collection or models to sort
   *
   * @returns {Model[]} - The sorted models
   */
  static sortModelsBy (attribute, collectionModels) {
    const models = collectionModels instanceof Collection
      ? collectionModels.models
      : collectionModels
    return models.sort((a, b) => {
      const attrA = a.getAttribute(attribute).toLowerCase()
      const attrB = b.getAttribute(attribute).toLowerCase()
      return attrA < attrB ? -1 : attrA > attrB ? 1 : 0
    })
  }
}
