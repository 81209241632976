import * as loadQueueHelper from './load-queue'
import { smooth } from './scroll'

const getOffsetTop = (element) => {
  if (!element) return 0
  return getOffsetTop(element.offsetParent) + element.offsetTop
}

const getCollapsibleParentId = (element) => {
  if (!element) return false
  if (element.classList.contains('c-collapse')) {
    return element.id
  }
  return getCollapsibleParentId(element.parentElement)
}

const initAnchorLinks = async () => {
  const anchors = [...document.querySelectorAll('[href*="#"]')]
    .filter(anchor => anchor.hash)
  anchors.forEach((anchor) => {
    const scrollOffset = 80
    const collapsibleParentId = getCollapsibleParentId(anchor)
    if (collapsibleParentId) {
      anchor.setAttribute('data-c-collapse__id', collapsibleParentId)
      anchor.setAttribute('data-c-collapse__action', 'open')
    }
    anchor.onclick = (e) => {
      const scrollingElement = document.scrollingElement || document.documentElement
      const eventTarget = (e.target.tagName === 'A') ? e.target : e.target.parentElement
      if (!eventTarget.hash) return false
      const targetName = eventTarget.hash.substr(1)
      const targetElementByName = document.getElementsByName(targetName)
      const targetElementById = document.getElementById(targetName)
      const targetElement = (targetElementByName) ? targetElementByName[0] : (targetElementById) ? targetElementById[0] : null
      if (targetElement) {
        e.preventDefault()
        smooth(scrollingElement, 0, getOffsetTop(targetElement) - scrollOffset)
      }
    }
  })
}

loadQueueHelper.add(function () {
  initAnchorLinks()
}, 0)
