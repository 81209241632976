/**
 * Creates and Gets Collection object with collection's object or string property
 */
export function getProcessedCollectionDataFromItem (item) {
  if (!item || !item.collection) return null
  let { collection } = item
  if (typeof collection !== 'object') {
    collection = {
      text: collection,
      id: collection.toLowerCase()
    }
  }
  return collection
}

export function getCollectionIcon (collection) {
  if (collection.id === 'primavera') {
    return 'primavera'
  } else if (collection.id === 'atlantis') {
    return 'spark-atlantis'
  } else if (collection.id === 'totally_snow') {
    return 'spark-totally'
  } else if (WEBPACK_BRAND === 'sunweb') { // eslint-disable-line
    return 'spark'
  }
  return ''
}
