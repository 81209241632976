export default [
  {
    name: 'xxxxs',
    width: 240
  },
  {
    name: 'xxxs',
    width: 320
  },
  {
    name: 'xxs',
    width: 480
  },
  {
    name: 'xs',
    width: 600
  },
  {
    name: 'sm',
    width: 960
  },
  {
    name: 'md',
    width: 1280
  },
  {
    name: 'lg',
    width: 1440
  },
  {
    name: 'xl',
    width: 1920
  }
]
