import { fetch as whatwgFetch } from 'whatwg-fetch'

export const defaultApiCallerOptions = {
  method: 'GET',
  timeout: 30000,
  body: undefined,
  isForm: false,
  isJsonResponse: true
}

/**
 * TODO: fix this documentation as it doesn't work with VSCode intellisense (I don't know why)
 * The ApiCallerOptions contains options for the fetcher than can be overriden
 *
 * @typedef {Object}          ApiCallerOptions
 *
 * @property {string} [method=GET]            - GET|POST|PATCH
 * @property {int} [timeout=30000]            - (Optional) timeout set to 30 seconds by default
 * @property {object} [body=undefined]        - (Optional) The payload for the POST or PATCH operations
 * @property {boolean} [isForm=false]         - (Optional) Indicates if the call is performed by a form, so headers and body
*                                                can be treated in a different way
 * @property {boolean} [isJsonResponse=true]  - (Optional) Indicates if the response is returned as a parsed JSON, else is returned as plain text
 */

/**
 * Executes a call to an API
 *
 * @param {string} uri                  - Full url to execute the action
 * @param {ApiCallerOptions} [options]  - The ApiCallerOptions
  *
 * @returns {object} result object with 'success{bool},response{object},error{object}'
 *
 */
export async function apiCaller (uri, options) {
  options = Object.assign({}, defaultApiCallerOptions, options)
  const fetcher = _getFetcher()
  const headers = _getHeaders(options.isForm)
  const bodyToSend = _getBody(options.body, options.isForm)
  const fetchOptions = _getFetchOptions(options, headers, bodyToSend)

  const result = {}
  try {
    const timeoutCall = new Promise(resolve => {
      const wait = setTimeout(() => {
        clearTimeout(wait)
        resolve('timeout')
      }, options.timeout)
    })

    const fetchCall = fetcher(uri, fetchOptions)

    const response = await Promise.race([
      timeoutCall,
      fetchCall
    ])

    if (response === 'timeout') {
      result.error = 'timeout'
      result.success = false
    } else {
      if (response.status === 200 || response.status === 201 || response.status === 202) {
        result.response = options.isJsonResponse
          ? await response.json()
          : await response.text()
        result.success = true
      } else {
        result.error = response.status
        result.success = false
        result.response = options.isJsonResponse
          ? await response.json()
          : await response.text()
      }
    }
    return result
  } catch (error) {
    result.error = error
    result.success = false
    return result
  }
}

/**
 * Returns the needed headers to perform the call
 *
 * @param {bool} isForm - If true, will return the needed headers to validate form Data
 */
function _getHeaders (isForm) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XmlHttpRequest'
  }
  const formHeaders = {
    Accept: 'application/json; charset=utf-8'
  }
  return isForm ? formHeaders : headers
}

/**
 * Returns the needed body (if set) to perform the call else returns undefined
 *
 * @param {object} body - The payload to be sent in the call.
 * @param {bool} isForm - Indicates if the call is being done by a form. If it is not a form,
 *                        body is sent as Json, but if it's a form, then the body is sent raw
 */
function _getBody (body, isForm) {
  return isForm ? body : body ? JSON.stringify(body) : undefined
}

/**
 * Returns the instance of the 'fetcher' that will actually execute the request.
 * As node.js doesn't have the window object we use the whatwgFetch for executing the unit tests with jest
 * whatwgFetch can also be used to extend the apiCaller with an abortable fetcher
 * (see:../components/Autocomplete/main.js)
 *
 * @returns {object} result object with 'success{bool},response{object},error{object}'
 */
function _getFetcher () {
  return window ? window.fetch : whatwgFetch
}

/**
 * Returns the needed options to execute the fetcher call
 *
 * @param {object} options - The options
 */
function _getFetchOptions (options, headers, bodyToSend) {
  const fetchData = {
    credentials: 'include',
    mode: 'cors',
    method: options.method.toUpperCase(),
    headers,
    referrerPolicy: 'no-referrer-when-downgrade'
  }

  // Only add body field if its really needed, else some old browser doesn't handle this properly (Edge16)
  if (bodyToSend) {
    fetchData.body = bodyToSend
  }
  return fetchData
}
