import { registeredWidgets } from './widget-directory'

const widgetApi = 'data-js-widget'

export default function initDocumentWidgetsFromAPI (htmlFragment = window.document) {
  // Find for candidates
  const widgetCandidates = htmlFragment.querySelectorAll(`[${widgetApi}]`)

  // Group candidates per widget type
  const sortedCandidates = {}
  widgetCandidates.forEach(element => {
    const dataApiContent = element.getAttribute(widgetApi).split(' ')
    dataApiContent.forEach(api => {
      if (element[api]) return
      sortedCandidates[api] = sortedCandidates[api] || []
      sortedCandidates[api].push(element)
    })
  })

  // Initialize widgets by its registered order
  registeredWidgets.forEach(({ name, constructor, options, instances }) => {
    const elementCandidates = sortedCandidates[name] || []

    elementCandidates.forEach(el => {
      const widgetInstance = new constructor(el, options)
      instances.push(widgetInstance)
    })
  })

  allWidgetsRenderedEvent()
}

const allWidgetsRenderedEvent = () => {
  const widgetsLoadedEvent = new window.CustomEvent('widgetsLoaded', { bubbles: true, cancelable: false })
  window.dispatchEvent(widgetsLoadedEvent)
}
