export default {
  xxs: 1,
  xs: 480,
  sm: 600,
  sml: 840,
  md: 960,
  lg: 1280,
  xl: 1440,
  xxl: 1600,
  xxxl: 1920
}
