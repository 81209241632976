// Element isConnected
if (Object.prototype.hasOwnProperty.call(window.Node.prototype, 'isConnected') === false) {
  Object.defineProperty(window.Node.prototype, 'isConnected', {
    configurable: true,
    enumerable: true,
    get: function () {
      return (this.closest('body') !== null)
    }
  })
}
