
// Import needed polyfills to support legacy browsers

// External polyfills
require('classlist-polyfill')
require('custom-event-polyfill')
require('url-polyfill')
require('whatwg-fetch')
require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only')

// Internal polyfills
require('./array-find')
require('./element-after')
require('./element-closest')
require('./element-dataset')
require('./element-replace-with')
require('./element-get-attribute-names')
require('./node-is-connected')
require('./node-list-iterable')
require('./object-assign')
require('./promise')
require('./string-startswith')
