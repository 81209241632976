import * as namespace from '../document/namespace'

const core = namespace.register('sundio.core')
core.idCounter = core.idCounter || 0

/**
 * Generates unique, mimicking the underscore _.uniqueId
 * @param {String} [prefix] - The prefix of the id
 * @returns {String} - The unique id
 */
export default function uid (prefix) {
  const id = ++core.idCounter + ''
  return prefix ? prefix + id : id
}
