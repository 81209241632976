import * as namespace from './namespace'
import { requestAnimationFrame } from './requestAnimationFrame'

const helper = namespace.register('sundio.helpers.loadQueue')
helper.queue = helper.queue || []
helper.ran = false
helper.isDocumentReady = false
helper.isDocumentRendered = false

/**
 * add
 * Add a function to the queue, or run immediately if page is loaded
 *
 * @param {Function} functionToCall
 * @param {Number} [level]
 */
helper.add = function (functionToCall, level) {
  if (helper.ran && typeof functionToCall === 'function') {
    functionToCall()
  } else {
    level = level || 1
    helper.queue[level] = helper.queue[level] || []
    helper.queue[level].push(functionToCall)
  }
}
export function add (functionToCall, level) {
  helper.add(functionToCall, level)
}

/**
 * runLevel
 * Run specific level of the queue
 *
 * @param {Number} level
 */
helper.runLevel = function (level) {
  while (helper.queue[level].length) {
    if (typeof helper.queue[level][0] === 'function') helper.queue[level][0]()
    helper.queue[level].shift()
  }
}
export function runLevel (level) {
  helper.runLevel(level)
}

/**
 * run
 * Run the queue
 *
 */
helper.run = function () {
  while (helper.queue.length) {
    if (helper.queue[0] && helper.queue[0].length) helper.runLevel(0)
    helper.queue.shift()
  }
}

export function autoload () {
  function _runWhenReadyRendered (opc = 'ready') {
    helper.isDocumentReady |= (opc === 'ready')
    helper.isDocumentRendered |= (opc === 'rendered')
    if (!helper.ran && helper.isDocumentReady && helper.isDocumentRendered) {
      helper.run()
      helper.ran = true
    }
  }

  if (!window?.sunwebgroup_legacy_design_system?.REACT_REHYDRATION_MODE_ENABLED) {
    requestAnimationFrame(() => _runWhenReadyRendered('rendered'))
    document.addEventListener('DOMContentLoaded', () => _runWhenReadyRendered('ready'))
  } else {
    if (!window?.sunwebgroup_legacy_design_system) {
      window.sunwebgroup_legacy_design_system = {}
    }

    window.sunwebgroup_legacy_design_system.init_legacy_components = () => {
      helper.run()
      helper.ran = true
    }
  }
}
