import { addDays, dateStringToDate } from '../helpers/dates'

const LOCAL_STORAGE = window.localStorage
const SESSION_STORAGE = window.sessionStorage
const STRING_EXP = /^"(.*)"$/

/**
 * The CookieSettings contains all data for a cookie
 *
 * @typedef {Object}              CookieSettings
 *
 * @property {String}             [path]              - The path of the cookie
 * @property {Date|DateString}    [expirationDate]    - The date to expire the cookie
 * @property {Integer}            [expirationDays]    - The number of days to expire the cookie from now on (not used if there's an expirationDate)
 * @property {String}             [domain]            - The cookie domain
 */

/*
 * TODO: Reenable codification
 * All encoding, done with encodeURIComponent, has been disabled for retro-compatibility with SG in Sitecore, whichstores all the cookies without encoding.
 * When DS goes live all encoding will have to be enabled again, and Sitecore will have to decode all cookies where/when needed
 */

const defaultCookieSettings = {
  path: '/'
}

class Storage {
  /**
   * DOM Events manager constructor.
   *
   * @constructor
   * functionMap - saves all the functions for every {{namespace}}.{{event}}
   */
  constructor () {
    this.local = {
      get: (key) => this._getStorage(LOCAL_STORAGE, key),
      set: (key, value) => this._setStorage(LOCAL_STORAGE, key, value),
      delete: (key) => LOCAL_STORAGE.removeItem(key)
    }
    this.session = {
      get: (key) => this._getStorage(SESSION_STORAGE, key),
      set: (key, value) => this._setStorage(SESSION_STORAGE, key, value),
      delete: (key) => SESSION_STORAGE.removeItem(key)
    }
    this.cookie = {
      get: (key) => this._getCookie(key),
      set: (key, value, cookieSettings) => this._setCookie(key, value, cookieSettings),
      delete: (key) => this._deleteCookie(key)
    }
  }

  _getStorage (storageType, key) {
    return this._getValue(storageType.getItem(key))
  }

  _setStorage (storageType, key, value) {
    if (!key) { return }
    storageType.setItem(key, this._setValue(value))
  }

  _getCookie (key) {
    if (!key) { return }
    // let value = window.document.cookie.match(new RegExp(encodeURIComponent(key) + '=([^;]+)'))
    let value = window.document.cookie.match(new RegExp('(?:^|;\\s*)' + key + '=([^;]+)'))
    value && (value = this._getValue(value[1]))
    return value
  }

  /**
   * Sets a cookie with given key, value and settings
   *
   * @param {String} key
   * @param {*} value
   * @param {CookieSettings} cookieSettings
   */
  _setCookie (key, value, cookieSettings) {
    if (!key) { return }
    cookieSettings = { ...defaultCookieSettings, ...cookieSettings }
    const expirationDate = cookieSettings.expirationDate
      ? dateStringToDate(cookieSettings.expirationDate)
      : cookieSettings.expirationDays
        ? addDays(new Date(), cookieSettings.expirationDays)
        : null

    // window.document.cookie = `${encodeURIComponent(key)}=${this._setValue(value)}` +
    window.document.cookie = `${key}=${this._setValue(value)}` +
      ` ;path=${cookieSettings.path}` +
      `${expirationDate ? ` ;expires=${expirationDate.toUTCString()}` : ''}` +
      `${cookieSettings.domain ? ` ;domain=${cookieSettings.domain}` : ''}`
  }

  _deleteCookie (key) {
    // window.document.cookie = encodeURIComponent(key) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;'
    window.document.cookie = key + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;'
  }

  _getValue (value) {
    let result = value === 'undefined' ? undefined : (value === 'null' ? null : value)
    try {
      // result = JSON.parse(decodeURIComponent(value))
      result = JSON.parse(value)
    } catch (error) { }
    return result
  }

  _setValue (value = 'undefined') {
    value = JSON.stringify(value)
    if (value.match(STRING_EXP)) { value = value.replace(STRING_EXP, '$1') }
    // return encodeURIComponent(value)
    return value
  }
}

const storage = new Storage()
export default storage
