
/**
 * chunk
 * Chunks an array into smaller arrays of a specified size.
 * Extracted from: https://30secondsofcode.org/#chunk
 *
 * @param {Array} arr
 * @param {Number} size
 *
 * @returns {Array[]}
 *
 * Examples:
 * chunk([1, 2, 3, 4, 5], 2); // [[1,2],[3,4],[5]]
 */
export const chunk = (arr, size) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
}

/**
 * flatten
 * Flattens an array up to the specified depth.
 * Extracted from: https://www.30secondsofcode.org/snippet/flatten
 *
 * @param {Array} arr
 * @param {Number} [depth=1]
 *
 * @returns {Array}
 *
 * Examples:
 * flatten([1, [2], 3, 4]); // [1, 2, 3, 4]
 */
export const flatten = (arr, depth = 1) =>
  arr.reduce((a, v) => a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v), [])

/**
   *  Returns the unique values in the array
   *
   * @param {Array} arr The array to be returned with only the unique elements that it contains
   *
   * @returns {Array}
   */
export const uniq = (arr) => {
  return [...new Set(arr)]
}

/**
 * uniqueCombinations
 * Gets unique combinations of an array
 *
 * @param {Array} arr
 *
 * @returns {Array[]}
 *
 * Examples:
 * uniqueCombinations([a, b, c]);
 * Returns:
 * [ [a], [b], [a, b], [c], [a, c], [b, c], [a, b, c] ]
 */
export const uniqueCombinations = (arr = []) => (
  [...Array(Math.pow(2, arr.length))]
    .fill()
    .reduce((result, e, i) => (
      (i)
        ? [
            ...result,
            arr.reduce((combination, el, j) => (
              (i & Math.pow(2, j))
                ? [...combination, el]
                : combination
            ), [])
          ]
        : result
    ), [])
)
