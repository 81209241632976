
// Main namespaces
window.sundio = window.sundio || {}
window.sundio.helpers = window.sundio.helpers || {}

// Helper namespace
const helper = window.sundio.helpers.namespace = {}

/**
 * register
 * Registers a new namespace or get the current instance if exist previously
 *
 * @param {String} namespace
 * @returns {Object} current namespace
 */
export function register (namespace) {
  const nsCandidate = namespace.split('.')
  let nsBuilt = window
  if (nsCandidate[0] === 'window') nsCandidate.shift()
  nsCandidate.forEach(nsCandidateKey => {
    nsBuilt[nsCandidateKey] = nsBuilt[nsCandidateKey] || {}
    nsBuilt = nsBuilt[nsCandidateKey]
  })
  return nsBuilt
}

helper.register = register
