import { Danish as FlatpickrDa } from 'flatpickr/dist/l10n/da'
import { German as FlatpickrDe } from 'flatpickr/dist/l10n/de'
import { english as FlatpickrEn } from 'flatpickr/dist/l10n/default'
import { French as FlatpickrFr } from 'flatpickr/dist/l10n/fr'
import { Dutch as FlatpickrNl } from 'flatpickr/dist/l10n/nl'
import { Swedish as FlatpickrSv } from 'flatpickr/dist/l10n/sv'
import { Norwegian as FlatpickrNb } from 'flatpickr/dist/l10n/no'

export const da = FlatpickrDa
export const de = FlatpickrDe
export const en = FlatpickrEn
export const fr = FlatpickrFr
export const nl = FlatpickrNl
export const sv = {
  ...FlatpickrSv,
  rangeSeparator: ' till '
}
export const nb = FlatpickrNb
