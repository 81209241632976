
/**
 * Gets a token value from a DS token file
 */
export function getToken (category, value, tokensData) {
  if (!tokensData || !tokensData[category]) return
  const tokenValue = tokensData[category].values.find(v => { return v.name === value })
  return tokenValue || undefined
}

/**
 * Gets a token value class from a DS token file
 */
export function getTokenClass (category, value, tokensData) {
  const tokenValue = getToken(category, value, tokensData)
  if (!tokenValue) return ''
  return tokenValue.class || ''
}
