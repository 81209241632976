import EventEmitter from 'eventemitter3'

class RegisteredEvents {
  constructor () {
    this.events = new EventEmitter()
    this.components = []
    this.widgets = []

    if (!RegisteredEvents.instance) {
      RegisteredEvents.instance = this
    }
    return RegisteredEvents.instance
  }

  registerComponentEvents (componentApiName, eventsInstance, options) {
    this._registerEvents(componentApiName, eventsInstance, options, this.components, 'componentEventsRegistered')
  }

  registerWidgetEvents (widgetApiName, eventsInstance, options) {
    this._registerEvents(widgetApiName, eventsInstance, options, this.widgets, 'widgetEventsRegistered')
  }

  _registerEvents (apiName, eventsInstance, options, instances, eventNameToEmit) {
    if (!eventsInstance) return
    let element = instances[apiName]
    if (!element) {
      element = instances[apiName] = []
    }
    const instanceProperties = { events: eventsInstance, options, name: apiName }
    element.push(instanceProperties)
    this.events.emit(eventNameToEmit, { ...instanceProperties, name: apiName })
  }
}

const registeredEvent = new RegisteredEvents()
Object.freeze(registeredEvent)
export default registeredEvent
