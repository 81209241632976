export const bindClickEventToResults = (results, resultsLinks, funcToBind, widgetEvents) => {
  // let events = []
  for (let i = 0; i < resultsLinks.length; i++) {
    const index = i + 1
    const result = { ...results[i], position: index }
    const links = resultsLinks[i]
    if (Array.isArray(links)) {
      links.forEach(link => _addListener(link, result, widgetEvents, funcToBind))
    } else {
      _addListener(links, result, widgetEvents, funcToBind)
    }
  }
}

const _addListener = (link, result, widgetEvents, funcToBind) => {
  link.addEventListener('click', (ev) => funcToBind(ev, result, widgetEvents), true)
  // Attach event to slider images and acco card button
  const elClasses = [...link.parentElement.classList]
  if (elClasses.includes('c-search-result') || elClasses.includes('c-product-card')) {
    link.parentElement.querySelectorAll('.c-slider__item>a').forEach(e =>
      e.addEventListener('click', (ev) => funcToBind(ev, result, widgetEvents), true))
    // Select search result button OR product card button
    const button = link.parentElement.querySelector('.c-search-result__footer-bottom > .c-btn, .c-product-card__footer-price > .c-btn')
    if (button) button.addEventListener('click', (ev) => funcToBind(ev, result, widgetEvents), true)
  }
}
